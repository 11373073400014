@import "./config/Variables";

/* Reset CSS */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
/*b,*/ u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* Agregar más reglas aquí según sea necesario */

/* Normalización de estilos */

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:disabled{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.filter-search-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px
}

@media (max-width: 600px) {
  .filter-search-container {
    align-items: flex-start;
    flex-direction: column;
  }
}

.filter-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 18px;
}

.filter-buttons-container-ls {
  display: flex;
  justify-content: flex-start;
  gap: 18px;
}


@media (max-width: 600px) {
  .filter-buttons-container {
    //margin-top: 18px;
    flex-direction: column;
    align-items: stretch;
  }

  .filter-buttons-container .ant-btn {
    width: 100%;
  }

  .filter-buttons-container-ls {
    //margin-top: 18px;
    flex-direction: column;
    align-items: stretch;
  }

  .filter-buttons-container-ls .ant-btn {
    width: 100%;
  }
}

.filter-container{
  //gap: 18px
}

.layoutkit-flexbox.ant-pro-chat-list-item-message-content p {
  word-break: break-word;
}

/* expanded key antd table */

.ant-table-expanded-row > td {
  background: transparent !important;
  padding: 0 !important;
}
