@import "../../../../../config/Variables";

.bc-request-activation-details-left-sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.bc-request-activation-details-schedule-tab {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bc-request-activation-detail-modal-card-body-notes-padding .ant-card-body {
  padding: 1px 0 0 0;
}

.bc-request-activation-detail-modal-card-body-notes-padding .ant-card-body > div > div > div{
  padding-right: 0;
}

.bc-general-detail-tab-card .ant-card-head {
  background-color: #FAFAFA;
}

.bc-general-detail-special-border {
}

.bc-general-detail-class-children ul {
  margin-top: 10px;
  margin-left: 10px;
  list-style-type: disc;
  padding-left: 15px;
}

.bc-general-detail-class-children li::marker {
  font-size: 0.75em;
}

.bc-general-detail-class-container {
  display: flex;
  flex-wrap: wrap;
}

.bc-general-detail-class-children {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 10px 20px;
}

.bc-general-detail-class-container > .bc-general-detail-class-children {
  display: flex;
  flex-direction: column;
}

.bc-general-detail-class-children {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

@media (max-width: 600px) {
  .bc-general-detail-class-children {
    flex: 1 1 100%;
  }
}

.bc-left-sidebar-status {
  width: 100%;
}

.bc-left-sidebar-button-status {
  float: right;
}


