@import "../../config/Variables";

.home-subtitle {

}

.home-title {
  font-size: 24px;
  font-weight: 600;
}
