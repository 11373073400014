@import "../../config/Variables";

.custom-date-sequence-container {
  padding: 20px;
}

.custom-date-sequence-modal-content {
  display: flex;
  gap: 24px;
  max-height: 340px;
  overflow-x: auto;
}

.custom-date-sequence-month-tabs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 180px;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  overflow-y: auto;
  max-height: 100%;
}

.custom-date-sequence-month-tabs::-webkit-scrollbar {
  width: 6px;
}

.custom-date-sequence-month-tabs::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 3px;
}

.custom-date-sequence-month-tabs::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}

.custom-date-sequence-month-tabs::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.custom-date-sequence-month-tab {
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.3s;
  font-size: 13px;
  border: 1px solid transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-date-sequence-month-tab:hover {
  background-color: #f0f0f0;
  border-color: #d9d9d9;
}

.custom-date-sequence-month-tab.active {
  background-color: #e6f4ff;
  color: #1677ff;
  border-color: #91caff;
}

.custom-date-sequence-calendar-container {
  flex: 1;
  padding: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.custom-date-sequence-calendar-header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  padding: 4px 0 12px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 8px;
  font-weight: 500;
}

/* Override Ant Design Calendar styles */
.custom-date-sequence-calendar-container .ant-picker-calendar {
  background: transparent;
}

.custom-date-sequence-calendar-container .ant-picker-calendar .ant-picker-panel {
  border-top: none;
}

.custom-date-sequence-calendar-container .ant-picker-calendar-date {
  height: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-date-sequence-calendar-container .ant-picker-cell {
  padding: 1px 0;
}

.custom-date-sequence-calendar-container .ant-picker-content th {
  height: 20px;
  padding: 2px 0;
  font-size: 12px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
  border-bottom: 1px solid #f0f0f0;
}

.custom-date-sequence-calendar-container .ant-picker-content td {
  font-size: 12px;
}

.custom-date-sequence-calendar-container .ant-picker-calendar .ant-picker-content {
  margin-top: 4px;
}

.custom-date-sequence-calendar-container .ant-picker-calendar .ant-picker-content table {
  border-spacing: 2px;
}
