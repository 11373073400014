.password-recovery-main-container {
  display: flex;
  justify-content:center;
  background-image: url('../../assets/images/tasker_background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
}

.password-recovery-main-container-children {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.password-recovery-form-container {
  width: 400px;
  background-color: #F0F2F5;
  //height: 640px;
  padding: 80px 60px;
  border-radius: 20px;
  z-index: 1;

  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    padding: 80px 20px;
    width: calc(100% - 40px);
  }
}

.password-recovery-main-logo-container {
  display: flex;
  justify-content: center;
}

.password-recovery-main-logo-container > img {
  height: 160px;
  width: auto;
  max-width: 100%;
}

.password-recovery-main-title {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 450;
}
