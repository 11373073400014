@import "../../config/Variables";

.activation-header{
  background-color: #0c1d31;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
}

.dashboard-layout-title {
  width: 100%;
}

.dashboard-layout-header-container{
  margin-bottom: 24px;
}

.trigger-menu-layout {
  cursor: pointer;
  color: white;
  font-size: 20px;
}

.trigger-menu-layout:hover {
  opacity: 0.85;
}
