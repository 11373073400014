.sign-in-main-container {
  display: flex;
  justify-content: flex-start;
  background-image: url('../../assets/images/tasker_background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;

  @media (max-width: 841px) {
    justify-content:center;
  }
}

.sign-in-main-container-children {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: rgba(31, 56, 103, 0.3);
  @media (max-width: 841px) {
    width: 100%;
    background: none;
  }
}

.sign-in-form-container {
  width: 400px;
  background-color: #F0F2F5;
  //height: 640px;
  padding: 80px 60px;
  border-radius: 20px;
  z-index: 1;

  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    padding: 80px 20px;
    width: calc(100% - 40px);
  }
}

.sign-in-main-logo-container {
  display: flex;
  justify-content: center;
}

.sign-in-main-logo-container > img {
  height: 160px;
  width: auto;
  max-width: 100%;
}

.sign-in-main-title {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 450;
}

.sign-in-forgotten-password {
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
}

.sign-in-forgotten-password:hover {
  opacity: 0.85;
}

.sign-in-forgotten-password:active {
  opacity: 1;
}
