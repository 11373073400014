.custom-user-dropdown .ant-dropdown {
  min-width: 280px;
}

.custom-user-dropdown .ant-dropdown-menu {
  padding: 8px 0 !important;
}

.custom-user-dropdown .ant-dropdown-menu-item {
  padding: 8px 16px !important;
}

.user-header {
  padding: 4px 16px;
}

.user-info-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: 600;
  font-size: 16px;
}

.user-company {
  color: #6b7280;
  font-size: 12px;
}

.user-email {
  color: #9ca3af;
  font-size: 12px;
}

.menu-icon {
  width: 16px;
  height: 16px;
}
