.power-bi-card-container {
  .ant-card-body {
    width: 100%;
    height: 100%;
  }
}

.powerbi-container {
  width: 100%;
  height: 100%;
}
