@import "../../../../../config/Variables";

.request-activation-details-left-sidebar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

.request-activation-details-information-tab {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.request-activation-detail-modal-card-body-notes-padding .ant-card-body {
  padding: 1px 0 0 0;
}

.request-activation-detail-modal-card-body-notes-padding .ant-card-body > div > div > div{
  padding-right: 0;
}


.client-general-detail-tab-card .ant-card-head {
  background-color: #FAFAFA;
}
